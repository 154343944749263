import { AnalyticsBrowser } from '@segment/analytics-next'

export interface PageProps {
	url: string
	hostname: string
	referrer: string
	path: string
	search: string
}

export interface WebVitalsEvent {
	id: string
	name: string
	delta: number
	deltaRounded: number
	navigationType: string
	rating: string
	value: number
	valueRounded: number
}

export const trackVideoEvent = (
	analytics: AnalyticsBrowser,
	pageProps: PageProps | null,
	eventDetails: any
) => {
	const eventName = eventDetails?.name
	delete eventDetails?.name
	try {
		analytics.track(eventName, {
			url: pageProps?.url,
			path: pageProps?.path,
			referrer: pageProps?.referrer,
			hostname: pageProps?.hostname,
			...eventDetails
		})
	} catch (error) {
		throw new Error(`Error tracking video event: ${error}`)
	}
}
